import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { blogService } from '@/services/blogService';
import { BlogPost as BlogPostType } from '@/types/blog';
import { format } from 'date-fns';
import { ArrowLeft } from 'lucide-react';
import SEO from '@/components/SEO';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const BlogPost = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [loading, setLoading] = useState(true);
  const [relatedPosts, setRelatedPosts] = useState<BlogPostType[]>([]);
  const navigate = useNavigate();

  // Function to extract first image URL from markdown content
  const extractFirstImage = (content: string): string | undefined => {
    const imageRegex = /!\[.*?\]\((.*?)\)/;
    const match = content.match(imageRegex);
    return match ? match[1] : undefined;
  };

  const loadPost = async (postId: string) => {
    try {
      const postData = await blogService.getPostById(postId);
      setPost(postData);
      
      if (postData && postData.tags.length > 0) {
        // Load related posts based on tags
        const { posts } = await blogService.getPosts();
        const related = posts
          .filter(p => p.id !== postId)
          .filter(p => p.tags.some(tag => postData.tags.includes(tag)))
          .sort((a, b) => {
            // Sort by number of matching tags
            const aMatches = a.tags.filter(tag => postData.tags.includes(tag)).length;
            const bMatches = b.tags.filter(tag => postData.tags.includes(tag)).length;
            return bMatches - aMatches;
          })
          .slice(0, 3);
        
        setRelatedPosts(related);
      }
    } catch (error) {
      console.error('Error loading post:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadPost(id);
    }
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-16 text-center">
          <h1 className="text-2xl font-bold mb-4">{t('blog.postNotFound')}</h1>
          <Link
            to="/blog"
            className="text-blue-400 hover:text-blue-300 inline-flex items-center"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            {t('blog.backToBlog')}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO
        titleKey={post.title}
        descriptionKey={post.excerpt || post.content.substring(0, 155)}
        path={`/blog/${id}`}
      />
      
      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-3xl mx-auto">
            <Link
              to="/blog"
              className="inline-flex items-center text-gray-400 hover:text-blue-400 mb-8"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              {t('blog.backToBlog')}
            </Link>

            <article>
              {post.coverImage && (
                <img
                  src={post.coverImage}
                  alt={post.title}
                  className="w-full h-64 md:h-96 object-cover rounded-lg mb-8"
                />
              )}

              <h1 className="text-4xl font-bold mb-6">{post.title}</h1>
              
              <div className="flex items-center gap-4 mb-8 text-gray-400">
                {post.authorImage && (
                  <img
                    src={post.authorImage}
                    alt={post.author}
                    className="w-10 h-10 rounded-full"
                  />
                )}
                <div className="flex items-center">
                  <span>{post.author}</span>
                  <span className="mx-2">•</span>
                  <span>{format(post.timestamp, 'MMMM dd, yyyy')}</span>
                </div>
              </div>

              {/* Add tags section */}
              {post.tags && post.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-8">
                  {post.tags.map((tag) => (
                    <span
                      key={tag}
                      className="px-3 py-1 bg-blue-900/50 text-blue-200 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}

              <div className="prose prose-invert prose-lg max-w-none">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                  className="markdown-content"
                  components={{
                    // Custom components for markdown elements
                    h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-8 mb-4" {...props} />,
                    h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-6 mb-3" {...props} />,
                    h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-4 mb-2" {...props} />,
                    p: ({node, ...props}) => <p className="mb-4" {...props} />,
                    a: ({node, ...props}) => (
                      <a className="text-blue-400 hover:text-blue-300 underline" {...props} />
                    ),
                    ul: ({node, ...props}) => <ul className="list-disc pl-6 mb-4" {...props} />,
                    ol: ({node, ...props}) => <ol className="list-decimal pl-6 mb-4" {...props} />,
                    blockquote: ({node, ...props}) => (
                      <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4" {...props} />
                    ),
                    code: ({className, children, ...props}) => 
                      children ? (
                        <code className="bg-gray-800 px-1 py-0.5 rounded text-sm font-mono" {...props} />
                      ) : (
                        <code className="block bg-gray-800 p-4 rounded-lg overflow-x-auto mb-4 font-mono" {...props} />
                      ),
                    img: ({node, ...props}) => (
                      <img className="rounded-lg max-w-full h-auto my-4" {...props} />
                    ),
                  }}
                >
                  {post.content}
                </ReactMarkdown>
              </div>

            </article>

            {/* Related Articles section */}
            {relatedPosts.length > 0 && (
              <div className="mt-16 border-t border-slate-800 pt-8">
                <h2 className="text-2xl font-bold mb-6">{t('blog.relatedArticles')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {relatedPosts.map((relatedPost) => {
                    const thumbnailImage = extractFirstImage(relatedPost.content);
                    return (
                      <div
                        key={relatedPost.id}
                        className="group cursor-pointer bg-slate-900/50 rounded-lg overflow-hidden border border-slate-800 hover:border-slate-700 transition-all"
                        onClick={() => {
                          navigate(`/blog/${relatedPost.id}`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <div className="aspect-video overflow-hidden">
                          {thumbnailImage ? (
                            <img
                              src={thumbnailImage}
                              alt={relatedPost.title}
                              className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                            />
                          ) : (
                            <div className="w-full h-full bg-slate-800 flex items-center justify-center">
                              <span className="text-slate-600">No image</span>
                            </div>
                          )}
                        </div>
                        <div className="p-4">
                          <h3 className="font-semibold mb-2 group-hover:text-blue-400 transition-colors">
                            {relatedPost.title}
                          </h3>
                          {relatedPost.excerpt && (
                            <p className="text-sm text-gray-400 line-clamp-2">
                              {relatedPost.excerpt}
                            </p>
                          )}
                          <div className="mt-2 flex flex-wrap gap-2">
                            {relatedPost.tags.slice(0, 2).map((tag) => (
                              <span
                                key={tag}
                                className="px-2 py-1 bg-blue-900/30 text-blue-200 rounded-full text-xs"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost; 