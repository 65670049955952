// Import Firebase SDK functions
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


// Firebase configuration using environment variables
const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics (only if supported and running in the browser)
// let analytics;
// if (typeof window !== 'undefined' && await isSupported()) {
//     analytics = getAnalytics(app);
//     console.log("Firebase Analytics initialized");
// } else {
//     console.log("Firebase Analytics not supported in this environment");
// }

// Initialize Authentication and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);

// Set local persistence for authentication
setPersistence(auth, browserLocalPersistence)
    .then(() => {
        console.log('Auth persistence set to local storage successfully.');
    })
    .catch((error) => {
        console.error('Error setting auth persistence:', error.code, error.message);
    });
