import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDocs, 
  getDoc,
  query,
  orderBy,
  limit,
  startAfter,
  DocumentSnapshot,
  Timestamp
} from 'firebase/firestore';
import { db, auth } from '@/lib/firebase';
import { BlogPost, BlogFormData } from '@/types/blog';

const POSTS_PER_PAGE = 9;

export const blogService = {
    createPost: async (data: BlogFormData): Promise<string> => {
        try {
          const user = auth.currentUser;
          if (!user) {
            throw new Error('User not authenticated');
          }
    
          // Create the blog post document with proper image fields
          const postData = {
            title: data.title,
            content: data.content,
            author: data.author,
            timestamp: Timestamp.now(),
            authorEmail: user.email,
            tags: data.tags || [],
            excerpt: data.excerpt || '',
            coverImage: data.coverImage || null,  // Store as single string
            authorImage: data.authorImage || null // Store as single string
          };
    
          console.log('Creating blog post:', postData);
          
          const docRef = await addDoc(collection(db, 'blogs'), postData);
          return docRef.id;
        } catch (error) {
          console.error('Error creating blog post:', error);
          throw error;
        }
      },

      updatePost: async (id: string, data: Partial<BlogFormData>): Promise<void> => {
        try {
          const docRef = doc(db, 'blogs', id);
          const updateData = {
            ...data,
            updatedAt: Timestamp.now(),
            tags: data.tags || [],
            excerpt: data.excerpt || '',
            coverImage: data.coverImage || null,
            authorImage: data.authorImage || null
          };
    
          console.log('Updating post with data:', {
            ...updateData,
            coverImage: updateData.coverImage ? 'Present' : 'Not present',
            authorImage: updateData.authorImage ? 'Present' : 'Not present'
          });
    
          await updateDoc(docRef, updateData);
        } catch (error) {
          console.error('Error updating blog post:', error);
          throw error;
        }
      },

  deletePost: async (id: string): Promise<void> => {
    try {
      const docRef = doc(db, 'blogs', id);
      await deleteDoc(docRef);
    } catch (error) {
      console.error('Error deleting blog post:', error);
      throw error;
    }
  },

  getPosts: async (lastDoc?: DocumentSnapshot): Promise<{
    posts: BlogPost[];
    lastDoc: DocumentSnapshot | undefined;
  }> => {
    try {
      let q = query(
        collection(db, 'blogs'),
        orderBy('timestamp', 'desc'),
        limit(POSTS_PER_PAGE)
      );

      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const snapshot = await getDocs(q);
      const posts = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          timestamp: data.timestamp instanceof Timestamp 
            ? data.timestamp.toDate() 
            : new Date(data.timestamp),
          tags: data.tags || [],
          excerpt: data.excerpt || ''
        } as BlogPost;
      });

      return {
        posts,
        lastDoc: snapshot.docs[snapshot.docs.length - 1]
      };
    } catch (error) {
      console.error('Error getting blog posts:', error);
      throw error;
    }
  },

  getPostById: async (id: string): Promise<BlogPost | null> => {
    try {
      const docRef = doc(db, 'blogs', id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          ...data,
          timestamp: data.timestamp instanceof Timestamp 
            ? data.timestamp.toDate() 
            : new Date(data.timestamp),
          tags: data.tags || [],
          excerpt: data.excerpt || ''
        } as BlogPost;
      }
      return null;
    } catch (error) {
      console.error('Error getting blog post:', error);
      throw error;
    }
  }
}; 