import React, { useContext, useState, useEffect, createContext } from 'react';
import { auth } from '@/lib/firebase';
import { onAuthStateChanged, getRedirectResult, User } from 'firebase/auth';

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({ currentUser: null, loading: true });

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          console.log('Redirect result in AuthContext:', result.user.email);
          setCurrentUser(result.user);
        }
      } catch (error) {
        console.error('Error handling redirect:', error);
      }
    };
  
    handleRedirect(); // Check for redirect result first
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user ? user.email : 'No user logged in');
      setCurrentUser(user);
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);
  return (
    <AuthContext.Provider value={{ currentUser, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
