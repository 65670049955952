import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './firebase';

const AUTHORIZED_EMAIL = import.meta.env.VITE_AUTHORIZED_EMAIL;

export const signInWithGoogle = async () => {
  try {
    // Add debug logs
    console.log('Starting Google sign in...');
    const provider = new GoogleAuthProvider();
    
    // Force select account to debug
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    
    const result = await signInWithPopup(auth, provider);
    console.log('Sign in successful:', result);
    
    if (result.user.email !== AUTHORIZED_EMAIL) {
      // If not authorized, sign out immediately
      await auth.signOut();
      throw new Error('Unauthorized email address');
    }
    
    return result.user;
  } catch (error: any) {
    // More detailed error logging
    console.error('Auth Error:', {
      code: error.code,
      message: error.message,
      fullError: error
    });
    
    throw error;
  }
};

export const signOut = () => auth.signOut(); 