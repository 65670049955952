import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en/translation.json';
import svTranslation from './locales/sv/translation.json';
import noTranslation from './locales/no/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      sv: {
        translation: svTranslation
      },
      no: {
        translation: noTranslation
      }
    },
    lng: 'sv', // default language
    fallbackLng: 'sv',
    supportedLngs: ['en', 'sv', 'no'],
    detection: {
      order: ['path', 'navigator', 'htmlTag'],
      lookupFromPathIndex: 0,
      caches: ['cookie'],
      cookieMinutes: 160,
      cookieDomain: 'intra-solutions.com'
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n; 