import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowRight, Send } from 'lucide-react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { toast } from 'sonner';
import { newsletterService } from '../services/newsletterService';
import { blogService } from '@/services/blogService';
import { BlogPost } from '@/types/blog';
import { format } from 'date-fns';


const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [latestPosts, setLatestPosts] = useState<BlogPost[]>([]);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      await newsletterService.subscribe({ email });
      toast.success(t('footer.newsletter.success'));
      setEmail('');
    } catch (error) {
      toast.error(t('footer.newsletter.error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadLatestPosts = async () => {
      try {
        const { posts } = await blogService.getPosts();
        setLatestPosts(posts.slice(0, 2)); // Get only the 2 most recent posts
      } catch (error) {
        console.error('Error loading latest posts:', error);
      }
    };

    loadLatestPosts();
  }, []);

  const quickLinks = [
    { name: 'navigation.home', href: '/' },
    { name: 'navigation.services', href: '/services' },
    { name: 'navigation.prices', href: '/prices' },
    { name: 'navigation.about', href: '/about' },
    { name: 'navigation.contact', href: '/contact' }
  ];

  return (
    <footer className="bg-gradient-to-b from-gray-900 via-gray-900 to-black text-gray-300">
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <div>
              <h3 className="text-2xl font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-cyan-400 to-purple-400">
                Intra Solutions
              </h3>
              <p className="text-gray-400 max-w-md">
                {t('footer.tagline')}
              </p>
            </div>
            
            {/* Newsletter Subscription */}
            <div className="pt-6">
              <h4 className="text-lg font-semibold mb-4 text-white">
                {t('footer.newsletter.title')}
              </h4>
              <p className="text-sm text-gray-400 mb-4">
                {t('footer.newsletter.description')}
              </p>
              <form onSubmit={handleSubscribe} className="flex gap-2">
                <Input
                  type="email"
                  placeholder={t('footer.newsletter.placeholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-800/50 border-gray-700 focus:border-blue-400"
                  required
                />
                <Button 
                  type="submit" 
                  variant="ghost" 
                  size="icon"
                  disabled={isLoading}
                >
                  <Send className={`h-5 w-5 ${isLoading ? 'animate-pulse' : ''}`} />
                </Button>
              </form>
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-6">
            <h4 className="text-lg font-semibold text-white">
              {t('footer.sections.quickLinks')}
            </h4>
            <nav className="grid grid-cols-1 gap-4">
              {quickLinks.map((link, index) => (
                <Link 
                  key={index}
                  to={link.href} 
                  className="flex items-center text-gray-400 hover:text-blue-400 transition-colors group"
                >
                  <ArrowRight className="w-4 h-4 mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                  {t(link.name)}
                </Link>
              ))}
            </nav>
          </div>

          {/* Latest Updates or Featured Content */}
          <div className="space-y-6">
            <h4 className="text-lg font-semibold text-white">
              {t('footer.sections.latestPosts')}
            </h4>
            <div className="space-y-4">
              {latestPosts.map((post) => (
                <Link 
                  key={post.id}
                  to={`/blog/${post.id}`}
                  className="block p-4 rounded-lg bg-gray-800/30 border border-gray-700/50 
                           hover:border-blue-400/50 transition-colors group"
                >
                  <p className="text-sm text-blue-400 mb-2">
                    {format(post.timestamp, 'MMMM d, yyyy')}
                  </p>
                  <h5 className="text-white font-medium mb-1 group-hover:text-blue-400 
                               transition-colors line-clamp-1">
                    {post.title}
                  </h5>
                  <p className="text-sm text-gray-400 line-clamp-2">
                    {post.excerpt || post.content.substring(0, 100)}...
                  </p>
                </Link>
              ))}

              {latestPosts.length === 0 && (
                <div className="p-4 rounded-lg bg-gray-800/30 border border-gray-700/50">
                  <p className="text-sm text-gray-400">
                    {t('footer.noPosts')}
                  </p>
                </div>
              )}
            </div>

            <Link 
              to="/blog" 
              className="inline-flex items-center text-blue-400 hover:text-blue-300 
                         transition-colors group text-sm"
            >
              {t('footer.viewAllPosts')}
              <ArrowRight className="w-4 h-4 ml-1 transform group-hover:translate-x-1 
                                   transition-transform" />
            </Link>
          </div>
        </div>
      </div>

      {/* Legal Links */}
      <div className="mt-8 pt-8 border-t border-gray-800">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="text-sm text-gray-400">
            © 2025 Intra Solutions. All rights reserved.
          </div>
          <div className="flex gap-6">
            <Link 
              to="/privacy-policy" 
              className="text-sm text-gray-400 hover:text-white transition-colors"
            >
              {t('footer.legal.privacy')}
            </Link>
            <Link 
              to="/terms" 
              className="text-sm text-gray-400 hover:text-white transition-colors"
            >
              {t('footer.legal.terms')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 