import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '@/lib/firebase';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { blogService } from '@/services/blogService';
import { BlogPost, BlogFormData } from '@/types/blog';
import { Button } from '@/components/ui/button';
import { Timestamp } from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { format } from 'date-fns';
import { toast } from 'sonner'; // Add toast notifications
import { uploadImage } from '../services/uploadService';

const initialFormData: BlogFormData = {
  title: '',
  content: '',
  author: '',
  tags: [],
  excerpt: '',
  images: []
};

const Admin = () => {
  const [user, setUser] = useState(auth.currentUser);
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [formData, setFormData] = useState<BlogFormData>(initialFormData);
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [tagInput, setTagInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        loadPosts();
      }
    });

    return () => unsubscribe();
  }, []);

  const formatDate = (timestamp: Timestamp | Date | any) => {
    try {
      if (timestamp instanceof Timestamp) {
        return format(timestamp.toDate(), 'MMM dd, yyyy');
      }
      return format(new Date(timestamp), 'MMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const loadPosts = async () => {
    try {
      setIsLoading(true);
      const { posts } = await blogService.getPosts();
      setPosts(posts);
    } catch (error) {
      toast.error('Failed to load posts');
      console.error('Error loading posts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      toast.success('Successfully logged in');
    } catch (error) {
      toast.error('Failed to log in');
      console.error('Error logging in:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/admin');
      toast.success('Successfully logged out');
    } catch (error) {
      toast.error('Failed to log out');
      console.error('Error logging out:', error);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setIsEditing(false);
    setEditingId(null);
    setTagInput('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (isEditing && editingId) {
        await blogService.updatePost(editingId, formData);
        toast.success('Post updated successfully');
      } else {
        await blogService.createPost(formData);
        toast.success('Post created successfully');
      }
      resetForm();
      loadPosts();
    } catch (error) {
      toast.error(isEditing ? 'Failed to update post' : 'Failed to create post');
      console.error('Error saving post:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (post: BlogPost) => {
    setFormData({
      title: post.title,
      content: post.content,
      author: post.author,
      tags: post.tags,
      excerpt: post.excerpt || '',
      images: post.images || []
    });
    setIsEditing(true);
    setEditingId(post.id);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        setIsLoading(true);
        await blogService.deletePost(id);
        toast.success('Post deleted successfully');
        loadPosts();
      } catch (error) {
        toast.error('Failed to delete post');
        console.error('Error deleting post:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddTag = () => {
    if (tagInput.trim() && !formData.tags.includes(tagInput.trim())) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, tagInput.trim()]
      }));
      setTagInput('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files;
      if (!files) return;

      setIsLoading(true);
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        
        // Validate file size
        const MAX_SIZE = 5 * 1024 * 1024; // 5MB
        if (file.size > MAX_SIZE) {
          toast.error(`Image ${file.name} is larger than 5MB`);
          continue;
        }

        const imageUrl = await uploadImage(file);
        
        // Add the new image URL to the form data
        setFormData(prev => ({
          ...prev,
          images: [...prev.images, imageUrl]
        }));

        // Insert the image URL at cursor position in content with correct markdown syntax
        const textarea = document.getElementById('content-textarea') as HTMLTextAreaElement;
        if (textarea) {
          const cursorPosition = textarea.selectionStart;
          const currentContent = formData.content;
          const imageName = file.name.replace(/\.[^/.]+$/, ""); // Remove file extension
          const markdownImage = `![${imageName}](${imageUrl})`; // Correct markdown syntax
          
          const newContent = 
            currentContent.substring(0, cursorPosition) +
            markdownImage +
            currentContent.substring(cursorPosition);
          
          setFormData(prev => ({
            ...prev,
            content: newContent
          }));
        }
      }

      toast.success('Images uploaded successfully');
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('Failed to upload images');
    } finally {
      setIsLoading(false);
    }
  };

  const ImageGallery = () => (
    <div className="grid grid-cols-3 gap-4 mt-4">
      {formData.images.map((imageUrl, index) => (
        <div key={index} className="relative group">
          <img 
            src={imageUrl} 
            alt={`Uploaded ${index + 1}`} 
            className="w-full h-32 object-cover rounded-lg"
          />
          <button
            type="button"
            onClick={() => {
              setFormData(prev => ({
                ...prev,
                images: prev.images.filter((_, i) => i !== index)
              }));
            }}
            className="absolute top-2 right-2 bg-red-500 p-2 rounded-full 
                     opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      ))}
    </div>
  );

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 flex items-center justify-center">
        <Button onClick={handleLogin} disabled={isLoading}>
          {isLoading ? 'Signing in...' : 'Sign in with Google'}
        </Button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mt-8 mb-8">
          <h1 className="text-3xl font-bold">Blog Admin</h1>
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-400">{user.email}</span>
            <Button onClick={handleLogout} variant="destructive" disabled={isLoading}>
              {isLoading ? 'Logging out...' : 'Logout'}
            </Button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4 mb-8 bg-slate-900/50 p-6 rounded-lg border border-slate-800">
          <h2 className="text-xl font-semibold mb-4">
            {isEditing ? 'Edit Post' : 'Create New Post'}
          </h2>
          
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-300">Cover Image</span>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="mt-1 block w-full text-sm text-gray-400
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-600 file:text-white
                          hover:file:bg-blue-700"
              />
            </label>
            {formData.images.length > 0 && (
              <div className="relative group">
                <img 
                  src={formData.images[0]} 
                  alt="Cover preview" 
                  className="w-full max-h-48 object-cover rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => setFormData(prev => ({ ...prev, images: [] }))}
                  className="absolute top-2 right-2 bg-red-500 p-2 rounded-full 
                           opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            )}
          </div>
          
          <Input
            placeholder="Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
            className="bg-slate-800"
          />
          
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-300">Upload Images</span>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                className="mt-1 block w-full text-sm text-gray-400
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-600 file:text-white
                          hover:file:bg-blue-700"
              />
            </label>
            
            <Textarea
              id="content-textarea"
              placeholder="Content (Markdown supported)"
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
              required
              className="min-h-[200px] bg-slate-800"
            />
            
            <ImageGallery />
          </div>
          
          <Textarea
            placeholder="Excerpt (optional)"
            value={formData.excerpt || ''}
            onChange={(e) => setFormData({ ...formData, excerpt: e.target.value })}
            className="min-h-[100px] bg-slate-800"
          />
          
          <Input
            placeholder="Author"
            value={formData.author}
            onChange={(e) => setFormData({ ...formData, author: e.target.value })}
            required
            className="bg-slate-800"
          />

          <div className="space-y-2">
            <div className="flex gap-2">
              <Input
                placeholder="Add tag"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), handleAddTag())}
                className="bg-slate-800"
              />
              <Button type="button" onClick={handleAddTag} variant="secondary">
                Add Tag
              </Button>
            </div>
            
            <div className="flex flex-wrap gap-2">
              {formData.tags.map((tag) => (
                <span
                  key={tag}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-900 text-blue-100"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleRemoveTag(tag)}
                    className="ml-1 text-blue-200 hover:text-blue-50"
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>

          <div className="flex gap-2">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : isEditing ? 'Update Post' : 'Create Post'}
            </Button>
            {isEditing && (
              <Button type="button" variant="outline" onClick={resetForm}>
                Cancel Edit
              </Button>
            )}
          </div>
        </form>

        <div className="bg-slate-900/50 p-6 rounded-lg border border-slate-800">
          <h2 className="text-xl font-semibold mb-4">Posts</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Title</TableHead>
                <TableHead>Author</TableHead>
                <TableHead>Date</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {posts.map((post) => (
                <TableRow key={post.id}>
                  <TableCell className="font-medium">{post.title}</TableCell>
                  <TableCell>{post.author}</TableCell>
                  <TableCell>
                    {formatDate(post.timestamp)}
                  </TableCell>
                  <TableCell className="space-x-2">
                    <Button
                      onClick={() => handleEdit(post)}
                      variant="secondary"
                      size="sm"
                      disabled={isLoading}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDelete(post.id)}
                      variant="destructive"
                      size="sm"
                      disabled={isLoading}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Admin;