import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Services from './pages/Services';
import Booking from './pages/Booking';
import About from './pages/About';
import Contact from './pages/Contact';
import './i18n';
import { Suspense } from 'react';
import { Loader2 } from 'lucide-react';
import BookingConfirmation from './pages/BookingConfirmation';
import BookingSuccess from './pages/BookingSuccess';
import SharePointDev from './pages/SharePointDev';
import PowerAppsDev from './pages/PowerAppsDev';
import PowerAutomate from './pages/PowerAutomate';
import DocumentManagement from './pages/DocumentManagement';
import AIIntegration from './pages/AIIntegration';
import Microsoft365Consulting from './pages/Microsoft365Consulting';
import ScrollToTop from './components/ScrollToTop';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO';
import Prices from './pages/Prices';
import PriceInquirySuccess from './pages/PriceInquirySuccess';
import Terms from './pages/Terms';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import  Admin  from './pages/Admin';
import { Login } from './components/Login';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProvider } from './hooks/useThemeProvider';
import ProtectedRoute from './components/ProtectedRoute';
import { useTranslation } from 'react-i18next';

// Custom loading component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <Loader2 className="h-8 w-8 animate-spin text-cyan-400" />
  </div>
);

const App: React.FC = () => {
  const { i18n } = useTranslation();
  
  // Language redirect component
  const LanguageRedirect = () => {
    const userLang = navigator.language.split('-')[0];
    const targetLang = ['sv', 'no', 'en'].includes(userLang) ? userLang : 'sv';
    return <Navigate to={`/${targetLang}`} replace />;
  };

  return (
    <ThemeProvider>
    <AuthProvider>
    <HelmetProvider>
      <SEO />
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {/* Redirect root to language-specific route */}
            <Route path="/" element={<LanguageRedirect />} />
            
            {/* Language-specific routes */}
            <Route path="/:lang" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="blog" element={<Blog />} />
              <Route path="services" element={<Services />} />
              <Route path="booking" element={<Booking />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="booking/confirm" element={<BookingConfirmation />} />
              <Route path="booking/success" element={<BookingSuccess />} />
              <Route path="services/sharepoint-development" element={<SharePointDev />} />
              <Route path="services/power-apps" element={<PowerAppsDev />} />
              <Route path="services/power-automate" element={<PowerAutomate />} />
              <Route path="services/document-management" element={<DocumentManagement />} />
              <Route path="services/ai-integration" element={<AIIntegration />} />
              <Route path="services/microsoft-365" element={<Microsoft365Consulting />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="prices" element={<Prices />} />
              <Route path="price-inquiry-success" element={<PriceInquirySuccess />} />
              <Route path="terms" element={<Terms />} />
              <Route path="blog/:id" element={<BlogPost />} />
              <Route path="admin" element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              } />
              <Route path="admin/login" element={<Login />} />
            </Route>
          </Routes>
        </Suspense>
        <CookieConsent />
      </BrowserRouter>
    </HelmetProvider>
    </AuthProvider>
    </ThemeProvider>
  );  
};

export default App;
