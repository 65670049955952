import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { blogService } from '@/services/blogService';
import { BlogPost } from '@/types/blog';
import { Button } from '@/components/ui/button';
import { format } from 'date-fns';
import { DocumentSnapshot } from 'firebase/firestore';
import SEO from '@/components/SEO';

const Blog = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState<DocumentSnapshot>();
  const [hasMore, setHasMore] = useState(true);

  // Function to extract first image from content
  const extractFirstImage = (content: string): string | undefined => {
    const imageRegex = /!\[.*?\]\((.*?)\)/;
    const match = content.match(imageRegex);
    return match ? match[1] : undefined;
  };

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = async (loadMore = false) => {
    try {
      setLoading(true);
      const { posts: newPosts, lastDoc: newLastDoc } = await blogService.getPosts(
        loadMore ? lastDoc : undefined
      );
      
      setPosts(prev => loadMore ? [...prev, ...newPosts] : newPosts);
      setLastDoc(newLastDoc);
      setHasMore(newPosts.length > 0);
    } catch (error) {
      console.error('Error loading posts:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO
        titleKey="seo.pages.blog.title"
        descriptionKey="seo.pages.blog.description"
        path="/blog"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 pb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            {t('blog.title')}
          </h1>
          
          {loading && posts.length === 0 ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {posts.map((post) => {
                  const thumbnailImage = post.coverImage || extractFirstImage(post.content);
                  return (
                    <Link
                      key={post.id}
                      to={`/${lang}/blog/${post.id}`}
                      className="group"
                    >
                      <article className="bg-gray-900/50 rounded-lg overflow-hidden border border-gray-800 hover:border-blue-500/50 transition-all duration-300">
                        {thumbnailImage && (
                          <div className="aspect-video w-full overflow-hidden">
                            <img
                              src={thumbnailImage}
                              alt={post.title}
                              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                            />
                          </div>
                        )}
                        <div className="p-6">
                          <h2 className="text-xl font-semibold mb-3 group-hover:text-blue-400 transition-colors">
                            {post.title}
                          </h2>
                          <p className="text-gray-400 mb-4 line-clamp-3">
                            {post.excerpt || post.content.substring(0, 150)}...
                          </p>
                          
                          {/* Tags section */}
                          {post.tags && post.tags.length > 0 && (
                            <div className="flex flex-wrap gap-2 mb-4">
                              {post.tags.map((tag) => (
                                <span
                                  key={tag}
                                  className="px-2 py-1 bg-blue-900/30 text-blue-200 rounded-full text-xs"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          )}

                          <div className="flex justify-between items-center text-sm text-gray-500">
                            <div className="flex items-center gap-2">
                              {post.authorImage && (
                                <img
                                  src={post.authorImage}
                                  alt={post.author}
                                  className="w-6 h-6 rounded-full"
                                />
                              )}
                              <span>{post.author}</span>
                            </div>
                            <span>{format(post.timestamp, 'MMM dd, yyyy')}</span>
                          </div>
                        </div>
                      </article>
                    </Link>
                  );
                })}
              </div>

              {hasMore && (
                <div className="flex justify-center mt-12">
                  <Button
                    onClick={() => loadPosts(true)}
                    disabled={loading}
                    variant="secondary"
                  >
                    {loading ? 'Loading...' : t('blog.loadMore')}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Blog; 