import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

export default function Layout() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  
  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <main><Outlet /></main>
      <Footer />
    </div>
  );
}