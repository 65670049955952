import { useTranslation } from 'react-i18next';
import { TranslatedSubject } from '../types/booking';

export const useTranslatedSubjects = (): TranslatedSubject[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'ai-automation',
      title: t('subjects.aiAutomation.title'),
      description: t('subjects.aiAutomation.description')
    },
    {
      id: 'ai-agents',
      title: t('subjects.aiAgents.title'),
      description: t('subjects.aiAgents.description')
    },
    {
      id: 'document-management',
      title: t('subjects.documentManagement.title'),
      description: t('subjects.documentManagement.description')
    },
    {
      id: 'sharepoint',
      title: t('subjects.sharepoint.title'),
      description: t('subjects.sharepoint.description')
    }
  ];
}; 