import React from 'react';
import { useTranslation } from 'react-i18next';
import SEO from '@/components/SEO';
import { Mail } from 'lucide-react';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        titleKey={t("seo.pages.terms.title")}
        descriptionKey={t("seo.pages.terms.description")}
        path="/terms"
      />

      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
              {t('terms.title')}
            </h1>

            <div className="text-gray-300 space-y-8">
              <p className="text-sm text-gray-400">
                {t('terms.effectiveDate')}: {t('terms.date')}
              </p>

              {/* Sections */}
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((section) => (
                <div key={section} className="space-y-4">
                  <h2 className="text-2xl font-semibold text-white">
                    {t(`terms.sections.${section}.title`)}
                  </h2>
                  <div className="prose prose-invert max-w-none">
                    {t(`terms.sections.${section}.content`)}
                  </div>
                </div>
              ))}

              {/* Contact Information */}
              <div className="mt-12 p-6 bg-gray-900/50 rounded-lg border border-gray-800">
                <h2 className="text-xl font-semibold mb-4">
                  {t('terms.contact.title')}
                </h2>
                <p className="flex items-center gap-2 text-gray-300">
                  <Mail className="w-5 h-5" />
                  <a 
                    href="mailto:support@intra-solutions.com"
                    className="text-blue-400 hover:text-blue-300 transition-colors"
                  >
                    support@intra-solutions.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms; 